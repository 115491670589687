import React from 'react'
import GenericLayout from '../layouts/GenericLayout';
import PageSection from '../components/PageSection';


import { graphql } from 'gatsby';
import { I18n } from 'react-i18next';
import { withI18next } from 'gatsby-plugin-i18next';
import { getContentTranslation } from '../helpers';



 const Inziniering = () => {
    return <GenericLayout>
        <PageSection>
            <header className="major">
                <h1>Inžiniering</h1>
            </header>
        
        </PageSection>
    </GenericLayout>
}

export default withI18next()(Inziniering)


export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;